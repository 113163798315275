import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"
import Prodopt from "../../../components/Prodopt"
import Prodfeat from "../../../components/Prodfeat"



const FDP = () => (
    <Layout>
        <Container>
            <div className="column">
                <br /><br />
                <div class="text-center">
                    {/* Картинка */}
                    <StaticImage
                        src="img/epsom-s.png"
                        width={790}
                        quality={50}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="EPSOM-S"
                    />
                </div>
                {/* Заголовок */}
                <h1 class="text-center py-3">
                EPSOM-S ДВУХРЯДНЫЙ. <br />ДВОЙНАЯ ПРУЖИНА. РАБОЧАЯ ГЛУБИНА 35.
                </h1>
                {/* Текст */}
                <p class="blockquote card px-4 mx-5 py-3">
                Чизельные культиваторы серии Ozdoken EPSOM это чизельный культиватор который позволяет выполнять рыхление на малой
глубине, но также может использоваться для глубокого рыхления почвы и смешивания
даже при большом количестве остатков культур. Горизонтальные двойные пружины
позволяют эффективный выпуск камней. </p>

                <h2 class="text-center py-3 text-black-50">
                    ПРЕИМУЩЕСТВА ПРОДУКТА
                </h2>

                <div class="row justify-content-center text-center px-5">
                    <Prodfeat text="35 см Рабочая глубина" />
                    <Prodfeat text="Установленный тип" />
                    <Prodfeat text="Двойная пружинная защита" />

                </div>

                {/* Преимущества */}
                <h2 class="text-center pt-3 text-black-50">
                    ОБОРУДОВАНИЕ ПРОДУКТА
                </h2>
                <div class="row px-3 justify-content-center">
                <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src= "img/637399135242644641-b75---cpy-550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt= "чизельный культиватор. Каток Чизеля"  style={{ marginBottom: `1.45rem` }} /><strong  class="h4">Каток Чизеля</strong><p class="h5">Одинарный ролик помогает подготовить   семенную почву. Также это помогает работать на оптимальной глубине.</p> </div> </div> </div>
<div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src= "img/637399137151846099-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt= "чизельный культиватор. Дополнительный Ролик"  style={{ marginBottom: `1.45rem` }} /><strong  class="h4">Дополнительный Ролик</strong><p class="h5"></p> </div> </div> </div>
<div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src= "img/637399136350921810-b75----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt= "чизельный культиватор. Система Штыря Безопасности"  style={{ marginBottom: `1.45rem` }} /><strong  class="h4">Система Штыря Безопасности</strong><p class="h5"></p> </div> </div> </div>

                </div>
                <h2 class="text-center py-3 text-black-50">
                    ТЕХНИЧЕСКИЕ СПЕЦИФИКАЦИИ
                </h2>
                {/* Таблица */}
                <div class="table-responsive">
                    <table class="table table-striped table-hover table-bordered">
                    
                    <thead class="background-dark-gray-ozdo">
        <tr>
        <th>Модель</th>
            <th>КОЛИЧЕСТВО<br />ЛАП</th>
            <th>РАССТОЯНИЕ<br />МЕЖДУ<br />ЛАПАМИ <br />(см)</th>
            <th>ВЕС <br />(кг)</th>
            <th> ДЛИНА <br />(см) </th>
            <th> ВЫСОТА <br />(см) </th>
            <th>ШИРИНА<br />(см)</th>
            <th>РАБОЧАЯ<br />ШИРИНА<br />(см)</th>
            <th colspan="2">ТРЕБУЕМАЯ<br />МОЩНОСТЬ</th>
        </tr>
        </thead><tbody class="align-middle">
        <tr>
            <td>CP&#8209;5</td>
            <td>5</td>
            <td rowspan="14">60</td>
            <td>455</td>
            <td rowspan="7">140</td>
            <td rowspan="14">140</td>
            <td>170</td>
            <td>150</td>
            <td>40-60 л.с.</td>
            <td>30-45 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;7</td>
            <td>7</td>
            <td>560</td>
            <td>220</td>
            <td>200</td>
            <td>60-80 л.с.</td>
            <td>45-60 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;9</td>
            <td>9</td>
            <td>675</td>
            <td>270</td>
            <td>250</td>
            <td>80-100 л.с.</td>
            <td>60-75 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;11</td>
            <td>11</td>
            <td>885</td>
            <td>320</td>
            <td>300</td>
            <td>100-120 л.с.</td>
            <td>75-89 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;13</td>
            <td>13</td>
            <td>1060</td>
            <td>370</td>
            <td>350</td>
            <td>120-140 л.с.</td>
            <td>89-104 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;15</td>
            <td>15</td>
            <td>1130</td>
            <td>420</td>
            <td>400</td>
            <td>140-160 л.с.</td>
            <td>104-120 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;17</td>
            <td>17</td>
            <td>1205</td>
            <td>470</td>
            <td>450</td>
            <td>160-180 л.с.</td>
            <td>120-134 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;M5</td>
            <td>5</td>
            <td>590</td>
            <td rowspan="7">200</td>
            <td>170</td>
            <td>150</td>
            <td>40-60 л.с.</td>
            <td>30-45 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;M7</td>
            <td>7</td>
            <td>720</td>
            <td>220</td>
            <td>200</td>
            <td>60-80 л.с.</td>
            <td>45-60 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;M9</td>
            <td>9</td>
            <td>860</td>
            <td>270</td>
            <td>250</td>
            <td>80-100 л.с.</td>
            <td>60-75 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;M11</td>
            <td>11</td>
            <td>1075</td>
            <td>320</td>
            <td>300</td>
            <td>100-120 л.с.</td>
            <td>75-89 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;M13</td>
            <td>13</td>
            <td>1265</td>
            <td>370</td>
            <td>350</td>
            <td>120-140 л.с.</td>
            <td>89-104 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;M15</td>
            <td>15</td>
            <td>1455</td>
            <td>420</td>
            <td>400</td>
            <td>140-160 л.с.</td>
            <td>104-120 кВт</td>
        </tr>
        <tr>
            <td>CP&#8209;M17</td>
            <td>17</td>
            <td>1650</td>
            <td>470</td>
            <td>450</td>
            <td>160-180 л.с.</td>
            <td>120-134 кВт</td>
        </tr>
        </tbody>
    
                    </table>
                </div>
            </div>
        </Container>
    </Layout>
);

export default FDP;